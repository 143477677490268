<template>
	<div>
		<UploadPreviewFile
			:uploadFilePath.sync="mpseaFile"
			:folderPath="'accounting/mpesa'"
			:format="'.csv'"
			:callbackFun="getFilePath"
			:isPreviewFile="false"
		>
			<template v-slot:btn>
				<span class="example-click" style="color: #ccc"> Please upload Mpesa statement from Mpesa's back end. </span>
				<a-button type="link" @click="showUploadHistory">Upload History</a-button>
			</template>
		</UploadPreviewFile>
		<p v-if="mpesaFilePageList && uploadSuccess" style="padding: 10px 0">
			<a :href="$Util.getObsImageUrl($store.state.pageState.authObj, mpesaFilePageList[0].filePath)" class="file_name" target="_blank">
				{{ !$Util.isEmpty(mpesaFilePageList[0].filePath) ? mpesaFilePageList[0].filePath.split('/').at(-1) : '' }}
			</a>
			<span>({{ 'from ' + mpesaFilePageList[0].startTime + ' to ' + mpesaFilePageList[0].endTime }})</span>
		</p>
		<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
			<template slot="action" slot-scope="{ columnData }">
				<span>
					<a-button :disabled="columnData.processStatus === 1" type="link" @click="uploadRepayment(columnData)">Upload</a-button>
					<a-divider type="vertical" />
					<a-button :disabled="columnData.processStatus === 1" type="link" @click="showIgnore(columnData.id)">Ignore</a-button>
				</span>
			</template>
		</search-list-table>
		<a-modal v-drag-modal v-model="mpesaFileListShow" :footer="false" width="1000px" title="Upload History">
			<a-table
				v-if="mpesaFilePageList"
				style="font-size: 12px"
				:columns="mpesaFileListColumns"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="mpesaFilePageList"
				:pagination="pagination"
				bordered
			></a-table>
		</a-modal>
		<upload-repayment ref="uploadRepayment" @repaymentsInitTable="repaymentsInitTable"></upload-repayment>
	</div>
</template>
<script>
import UploadRepayment from '@/views/businessComponents/UploadRepayment.vue'
import moment from 'moment'
import { apiMpesaPage, apiMpesaFilePage, apiMpesaIgnore, apiMpesaUploadFile } from '@/api/accountChecking'
export default {
	data() {
		return {
			mpesaFilePageList: null,
			mpesaFileListShow: false,
			mpseaFile: null,
			uploadSuccess: false,
			pagination: {
				total: 0,
				showTotal: (total) => `Total ${total} items`,
				hideOnSinglePage: false,
				current: 1,
				showSizeChanger: true,
				pageSize: 10,
				pageSizeOptions: ['10', '20', '50', '100']
			}
		}
	},
	components: { UploadRepayment },
	computed: {
		formSearchList() {
			return [
				// {
				// 	label: 'table.applyInfo.cloums.caseId',
				// 	type: 'input',
				// 	validateField: ['caseId']
				// },
				{
					label: 'table.finance.cloum.payID',
					type: 'input',
					validateField: ['receiptNo']
				},
				{
					label: 'table.loanInfo.caseInfo.status',
					type: 'select',
					selectOption: [0, 1],
					validateField: ['processStatus'],
					prefix: 'table.finance.cloum.processStatus'
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['clientName']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.finance.cloum.completionTime',
					type: 'range-picker',
					validateField: ['completionTime', { initialValue: [] }]
				}
			]
		},
		columns() {
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					align: 'center',
					width: 80,
					fixed: 'left'
				},
				{
					// 案件ID
					title: this.$t('table.finance.cloum.receiptNo'),
					dataIndex: 'id',
					align: 'center',
					width: 150,
					fixed: 'left'
				},
				{
					title: this.$t('table.finance.cloum.completionTime'),
					align: 'center',
					dataIndex: 'completionTime'
				},
				{
					title: this.$t('table.finance.cloum.TransAmount'),
					align: 'center',
					dataIndex: 'paidIn',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'clientName',
					align: 'center'
				},
				{
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					align: 'center',
					dataIndex: 'registrationNumber'
				},
				{
					title: this.$t('table.loanInfo.caseInfo.status'),
					align: 'center',
					dataIndex: 'processStatus',
					customRender: (v, o) => {
						return this.$t(`table.finance.cloum.processStatus.${v}`)
					}
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 290,
					fixed: 'right'
				}
			]
		},
		mpesaFileListColumns() {
			return [
				{
					title: 'File',
					dataIndex: 'filePath',
					align: 'center'
				},
				{
					title: 'Upload Time',
					dataIndex: 'uploadTime',
					align: 'center'
				},
				{
					title: 'Time Range',
					align: 'center',
					customRender: (obj) => {
						return 'from ' + obj.startTime + ' to ' + obj.endTime
					}
				},
				{
					title: 'Operator',
					align: 'center',
					dataIndex: 'username'
				}
			]
		}
	},
	methods: {
		moment,
		getFilePath(type) {
			this.uploadSuccess = false
			apiMpesaUploadFile({ filePath: type }).then((res) => {
				this.getApiMpesaFilePage()
				this.uploadSuccess = true
				this.$message.success('Success')
			})
		},
		getApiMpesaFilePage() {
			apiMpesaFilePage().then((res) => {
				this.mpesaFilePageList = res.content
				this.pagination.current = res.number + 1
				this.pagination.pageSize = res.size
				this.pagination.total = res.totalElements
			})
		},
		showUploadHistory() {
			this.getApiMpesaFilePage()
			this.mpesaFileListShow = true
		},
		uploadRepayment(columnData) {
			this.$refs.uploadRepayment.uploadRepayment(
				null,
				{
					paymentId: columnData.id,
					recordId: columnData.id,
					transactionDate: columnData.completionTime,
					amount: columnData.paidIn,
					transactionTypeEnum: 4
				},
				'mapesaAccountChecking'
			)
			this.repayModalShow = true
		},
		initDataSource(initParams) {
			return apiMpesaPage(initParams)
		},
		repaymentsInitTable(pageNum) {
			this.$refs.searchListTable.initTable(pageNum)
		},
		showIgnore(id) {
			this.$confirm({
				title: '',
				content: 'Are you sure not to process it?',
				onOk: () => {
					apiMpesaIgnore(id)
						.then(() => {
							this.$message.success('Success')
							this.$refs.searchListTable.initTable(1)
						})
						.catch((err) => {})
				},
				onCancel() {}
			})
		}
	}
}
</script>
<style lang="less">
.mpesa-upload {
	.upload {
		display: inline-block;
	}
	.tips {
		margin-left: 20px;
		opacity: 0.6;
	}
}

.send {
	display: flex;
	margin-top: 20px;
	.ant-form-item-label {
		font-weight: 600;
	}
	.ant-input,
	.ant-select {
		width: 360px;
		height: 40px;
		line-height: 40px;
	}
}
</style>
